var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cancel-confirmed" }, [
    _c("h1", { staticClass: "cancellation-title" }, [
      _vm._v("Service Cancellation Processed"),
    ]),
    _c("div", { staticClass: "cancel-message" }, [
      _c("p", [
        _vm._v("\n      Your cancellation has been processed. "),
        _vm.refundTotal > 0
          ? _c("span", [
              _vm._v(
                "You’ll receive an email shortly with details about your $" +
                  _vm._s(_vm.refundTotal.toFixed(2)) +
                  " refund, which will be credited back to your original payment method."
              ),
            ])
          : _vm._e(),
      ]),
      _c("p", [
        _vm._v(
          "\n      We’re sorry you're canceling your services. Whether it’s saving time, reducing costs, or keeping track of filing deadlines, we’re ready to help your business!\n    "
        ),
      ]),
      _c("p", [
        _vm._v(
          "\n      Please contact customer service for any questions\n    "
        ),
      ]),
      _c(
        "div",
        { staticClass: "button-container" },
        [
          _c(
            "b-button",
            {
              staticClass: "custom-button",
              attrs: { "aria-label": "Hire Us Button", to: "/hire-us" },
            },
            [
              _vm._v("\n        See Service Offers in Hire Us "),
              _c("fa-icon", { attrs: { icon: ["fas", "arrow-right"] } }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }