<template>
  <div class="cancel-confirmed">
    <h1 class="cancellation-title">Service Cancellation Processed</h1>
    <div class="cancel-message">
      <p>
        Your cancellation has been processed. <span v-if="refundTotal > 0">You’ll receive an email shortly with details about your ${{ refundTotal.toFixed(2) }} refund, which will be credited back to your original payment method.</span>
      </p>
      <p>
        We’re sorry you're canceling your services. Whether it’s saving time, reducing costs, or keeping track of filing deadlines, we’re ready to help your business!
      </p>
      <p>
        Please contact customer service for any questions
      </p>
      <div class="button-container">
        <b-button
          class="custom-button"
          aria-label="Hire Us Button"
          to="/hire-us"
        >
          See Service Offers in Hire Us <fa-icon :icon="['fas', 'arrow-right']" />
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'

export default {
  name: 'CancellationComplete',
  computed: {
    ...mapGetters('invoices', ['refundTotal']),
  },
}
</script>

<style lang="scss">
.cancel-confirmed {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  width: 100%;
  max-width: 711px;
  margin: 0 auto;
  background-color: #F7F7FB;

  .cancellation-title {
    width: 100%;
    text-align: left;
    margin-top: 0;
    margin-bottom: 2rem;
  }

  .cancel-message {
    width: 100%;
    text-align: left;
    font-weight: 400;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
  }

  .custom-button {
    background-color: #009FBF !important;
    border-radius: 5px !important;
    border: none;
    color: #FFFFFF;
  }
}
</style>
